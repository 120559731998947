import {Pagination} from 'antd'
import React, {useEffect, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {IMyComplainModel} from '../../../models/dashboard-page/IComplainModel'
import {KTSVG, toAbsoluteUrl} from '../../../../_Montdor/helpers'
import {
  getCustomerComplainApproveByAPI,
  getGetCustomerComplainListApi,
} from '../../dashboard/MyComplainCRUD'
import BlankDataImageInTable from '../../common-pages/BlankDataImageInTable'
import LoaderInTable from '../../common-pages/LoaderInTable'
import {Button, Modal} from 'react-bootstrap-v5'
import moment from 'moment'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {IProjectModel} from '../../../models/dashboard-page/IDashboardModel'
import BackButton from './Header/BackButton'
import InfoHeader from './Header/InfoHeader'

type Props = {
  // handoverDate: string
  // maintenanceDays: number
}

interface IUnit {
  loading: boolean
  MyComplainData: IMyComplainModel[]
  tmpMyComplainData: IMyComplainModel[]
  objData: IProjectModel
  imageShow: string
  selCustComplainID: number
  expComplainDate: string
  curntComplainDate: string
}

const ComplainListPage: React.FC<Props> = () => {
  const location = useLocation()
  const {projectId} = useParams<{projectId: string}>()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [state, setState] = useState<IUnit>({
    loading: false,
    MyComplainData: [] as IMyComplainModel[],
    tmpMyComplainData: [] as IMyComplainModel[],
    objData: {} as IProjectModel,
    imageShow: '',
    selCustComplainID: 0,
    expComplainDate: moment().format('YYYY-MM-DD'),
    curntComplainDate: moment().format('YYYY-MM-DD'),
  })

  useEffect(() => {
    // var d = new Date(handoverDate)
    // d.setDate(d.getDate() + maintenanceDays)
    // var dateString = moment(d).format('YYYY-MM-DD')
    setState({...state, loading: true})
    setTimeout(() => {
      let lc: any = location.state
      let data: any = lc.objData
      getCustomerComplainData(data)
    }, 100)
  }, [])

  function getCustomerComplainData(data: IProjectModel) {
    let d = new Date(data.statusDate)
    d.setDate(d.getDate() + data.maintenanceDays)
    let dateString = moment(d).format('YYYY-MM-DD')
    getGetCustomerComplainListApi(user.customerID, parseInt(atob(projectId)))
      .then((response) => {
        if (response.data.isSuccess == true) {
          const responseData = response.data.responseObject
          setState({
            ...state,
            MyComplainData: responseData,
            tmpMyComplainData: responseData,
            objData: data,
            expComplainDate: dateString,
            loading: false,
          })
          setTotal(responseData.length)
        } else {
          toast.error(`${response.data.message}`)
          setState({...state, MyComplainData: [], loading: false})
        }
      })
      .catch((error) => {
        toast.error(`${error}`)
        setState({...state, MyComplainData: [], loading: false})
      })
  }

  // ----------------------- For Complain Appove ------------------------
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleApprove = (custComplainID: number) => {
    setState({
      ...state,
      selCustComplainID: custComplainID,
      loading: false,
    })
    setShow(true)
  }

  function approveComplain(custComplainID: number) {
    getCustomerComplainApproveByAPI(custComplainID)
      .then((response) => {
        if (response.data.isSuccess === true) {
          toast.success('Approved Successfully.', {position: 'top-center'})
          getCustomerComplainData(state.objData)
          setShow(false)
        } else {
          toast.error(`${response.data.message}`, {position: 'top-center'})
          setState({...state, loading: false})
          setShow(false)
        }
      })
      .catch((error) => {
        toast.error(`${error}`, {position: 'top-center'})
        setState({...state, loading: false})
        setShow(false)
      })
  }

  // ==================== For Images Flag============
  const [showFlag, setShowFlag] = useState(false)
  const handleCloseFlag = () => {
    setState({...state, imageShow: '', loading: false})
    setShowFlag(false)
  }

  const onShowSizeChange = (current: any, pageSize: any) => {
    setPostPerPage(pageSize)
  }
  const [total, setTotal] = useState(state.MyComplainData.length) //  length
  const [page, setPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(10)
  const indexOfLastPage = page * postPerPage
  const indexOfFirstPage = indexOfLastPage - postPerPage
  const currentPosts: IMyComplainModel[] = state.MyComplainData.slice(
    indexOfFirstPage,
    indexOfLastPage
  )

  return (
    <>
      <div className='card border border-2 border-primary border my-9 p-3'>
        <InfoHeader objData={state.objData} />
        {/* begin::Header */}
        {/* {state.loading ? (
          <LoaderInTable loading={state.loading} column={15} />
        ) : (
          <>
            {state.objData.statusDate <= state.expComplainDate &&
            state.curntComplainDate <= state.expComplainDate ? (
              <div className='card-header border-0' style={{backgroundColor: '#404040'}}>
                <div></div>
                <div
                  className='card-toolbar'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Click to add Complain Request'
                >
                  <Link
                    className='btn btn-sm btn-light-primary bg-white'
                    to={{
                      pathname: `/dashboard/wrapper/project/my-complains/${btoa(
                        `${state.objData.projectID}`
                      )}/add`,
                      state: {objData: state.objData},
                    }}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                    Add New
                  </Link>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )} */}
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-striped align-middle g-6'>
              {/* begin::Table head */}
              <thead className='bg-light-primary'>
                <tr className='fw-bolder fs-5'>
                  <th className='w-200px'>Ticket Number</th>
                  <th className='w-200px'>Complain Date</th>
                  <th className='w-200px'>Agency Type</th>
                  <th className='w-250px'>Complain Status</th>
                  <th className='w-200px'>Complain By</th>
                  <th className='w-75px'>OTP</th>
                  <th className='w-75px'>Approve</th>
                  <th className='w-200px'>Description</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody className='text-start'>
                {state.loading ? (
                  <LoaderInTable loading={state.loading} column={15} />
                ) : (
                  <>
                    {currentPosts.length > 0 &&
                      currentPosts.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td className='min-w-50px'>
                              <span className='text-dark text-hover-primary fs-6'>
                                {data.vocuherNo}
                              </span>
                            </td>
                            <td className='min-w-50px'>
                              <span className='text-dark text-hover-primary fs-6'>
                                {data.complainDate}
                              </span>
                            </td>
                            <td className='min-w-50px'>
                              <span className='text-dark text-hover-primary fs-6'>
                                {data.agencyTypeName}
                              </span>
                            </td>
                            <td className='min-w-50px'>
                              <span className='text-dark text-hover-primary fs-6'>
                                {data.complainStatusName}
                              </span>
                            </td>
                            <td className='min-w-50px'>
                              <span className='text-dark text-hover-primary fs-6'>
                                {data.complainCreateByName}
                              </span>
                            </td>
                            <td className='min-w-50px'>
                              {data.statusID === 3 ? (
                                <span className='text-dark text-hover-primary fs-6'>
                                  {data.visitOTP}
                                </span>
                              ) : (
                                <span className='text-success text-hover-primary cursor-pointer fs-7 badge badge-light-primary'>
                                  N.A
                                </span>
                              )}
                            </td>
                            <td className='min-w-50px'>
                              {data.statusID === 5 ? (
                                <span
                                  className='text-success text-hover-primary cursor-pointer fs-7 badge badge-light-primary'
                                  onClick={() => handleApprove(data.customerComplainMainID)}
                                >
                                  Approve
                                </span>
                              ) : (
                                <span className='text-success text-hover-primary cursor-pointer fs-7 badge badge-light-primary'>
                                  N.A
                                </span>
                              )}
                            </td>
                            <td>
                              <span className='text-dark text-hover-primary fs-6'>
                                {data.description}
                              </span>
                            </td>
                          </tr>
                        )
                      })}
                    <BlankDataImageInTable
                      length={currentPosts.length}
                      loading={state.loading}
                      colSpan={15}
                    />
                  </>
                )}
              </tbody>
            </table>
            <div className='text-center'>
              <Pagination
                onChange={(value: any) => setPage(value)}
                pageSize={postPerPage}
                total={total}
                current={page}
                showSizeChanger
                showQuickJumper
                onShowSizeChange={onShowSizeChange}
                showTotal={(total) => `Total ${total} items`}
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------- Image Model -------------------------------  */}
      <Modal
        size='lg'
        show={showFlag}
        onHide={handleCloseFlag}
        backdrop='true'
        keyboard={false}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className='text-align-center'>Complain Image</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-5'>
            <img
              alt='image not found'
              className='img-fluid'
              src={
                state.imageShow == ''
                  ? toAbsoluteUrl('/media/img/NoProductImage.png')
                  : toAbsoluteUrl(`${state.imageShow}`)
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleCloseFlag}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* =====================Complain Approve Model=================== */}
      <Modal show={show} onHide={handleClose} backdrop='true' keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Approve Complain</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure ! You want to Approve</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='success' onClick={() => approveComplain(state.selCustComplainID)}>
            Approve
          </Button>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {ComplainListPage}
