import React from 'react'
import {useTabContext} from '../context/TabContext'
import {Photos3DPage} from '../pages/Photos3DPage'
import {MainPage} from '../pages/MainPage'

const TabContent: React.FC = () => {
  const {activeTab} = useTabContext()
  console.log(activeTab)
  return (
    <div>
      {/* {activeTab == 'home' && (
        <div> */}
          <MainPage />
        {/* </div>
      )} */}
      {activeTab == 'about' && <div>About Content</div>}
      {activeTab == 'contact' && <div>Contact Content</div>}
    </div>
  )
}

export default TabContent
