// =========================Get ProjectStatus_List=====================

import axios from 'axios'
const BASE_API_URL = process.env.REACT_APP_API_URL

// =================dashboard URL=====================
export const GET_ALL_Additional_Item_List = `${BASE_API_URL}/ProjectAdditionalItem/GetProjectAdditionalItemListByProjectID` //AdditionalItem
export const GET_ALL_Reduction_Item_List = `${BASE_API_URL}/ProjectReduction/GetProjectReductionListByProjectID` //AdditionalItem
export const GET_Project_Image_Structure_List = `${BASE_API_URL}/ProjectImage/GetProjectImageListByProjectID` //Image

// -----------------Additional Item Api -------------
export function getAllAdditionalItemListAPI(projectID: number) {
  return axios.post(GET_ALL_Additional_Item_List, {projectID})
}

// -----------------Reduction Item Api -------------
export function getAllReductionItemListAPI(projectID: number) {
  return axios.post(GET_ALL_Reduction_Item_List, {projectID})
}

// -----------------Additional Item Api -------------
export function getProjectProject3dImageList(projectID: number) {
  return axios.post(GET_Project_Image_Structure_List, {
    projectID,
  })
}
