import axios from 'axios'
const BASE_API_URL = process.env.REACT_APP_API_URL

// =================dashboard URL=====================
export const GET_PROJECT_LIST_By_Customer_ID = `${BASE_API_URL}/CustomerComplain/GetProjectListByCustomerID` //Get Admin Count

// =========================Get Admin=====================
export function getProjectListByCustomerIDApi(customerID:number) {
  return axios.post(GET_PROJECT_LIST_By_Customer_ID,{customerID})
}
