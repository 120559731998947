import React, {useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import BlankDataImageInTable from '../../common-pages/BlankDataImageInTable'
import {useTabContext} from '../context/TabContext'
import Header from '../components/Header'
import Loader from '../../common-pages/Loader'

const MainPage: React.FC = () => {
  const {data, loading} = useTabContext()

  async function downloadPojectFile(selURL: string) {
    let fullUrl: string = ''
    if (selURL == '') {
      return toast.warning('File Not Uploaded')
    } else {
      fullUrl = process.env.REACT_APP_API_URL + selURL
    }

    const link = document.createElement('a')
    link.target = '_blank'
    link.href = `${fullUrl}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  //   ------View on other tab --------------
  async function downloadQuotationFile(selURL: string) {
    let fullUrl: string = ''
    if (selURL == '') {
      return toast.warning('File Not Uploaded')
    } else {
      fullUrl = process.env.REACT_APP_API_URL + selURL
    }

    const link = document.createElement('a')
    link.target = '_blank'
    link.href = `${fullUrl}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className='mt-9'>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          {data.length > 0 &&
            data.map((datas: any, index: any) => {
              return (
                <div key={index} className='m-5 card border border-2 border-primary border'>
                  <Header objData={datas} />
                  <div className='card-body'>
                    <div className='d-flex w-100 flex-grow-2 flex-column'>
                      <div className='row mb-5'>
                        <label className='col-lg-3 fw-bold text-dark fs-6'> Project Name : </label>
                        <div className='col-lg-3'>
                          <span className='fw-bolder text-inverse fs-4 text-hover-primary'>
                            {datas.projectName}
                          </span>
                        </div>
                        <label className='col-lg-3 fw-bold text-dark fs-6'>
                          Project Category Name :{' '}
                        </label>
                        <div className='col-lg-3'>
                          <span className='fw-bolder fs-6 text-primary'>
                            {datas.projectCategoryName}
                          </span>
                        </div>
                      </div>
                      <div className='row mb-5'>
                        <label className='col-lg-3 fw-bold text-dark fs-6'>Project Type : </label>
                        <div className='col-lg-3'>
                          <span className='fw-bolder fs-6 text-primary'>{datas.projectType}</span>
                        </div>
                        <label className='col-lg-3 fw-bold text-dark fs-6'>Project Date : </label>
                        <div className='col-lg-3'>
                          <span className='fw-bolder fs-6 text-primary'>{datas.projectDate}</span>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-5'>
                      <label className='col-lg-3 fw-bold text-dark fs-6'>BHK Name :</label>
                      <div className='col-lg-3'>
                        <span className='fw-bolder fs-6 text-warning'>{datas.bhkName}</span>
                      </div>
                      <label className='col-lg-3 fw-bold text-dark fs-6'>Carpet Area :</label>
                      <div className='col-lg-3'>
                        <span className='fw-bolder fs-6 text-warning' style={{color: '#e95800'}}>
                          {datas.carpetArea}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-5'>
                      <label className='col-lg-3 fw-bold text-dark fs-6'>Project Amount :</label>
                      <div className='col-lg-3'>
                        <span className='fw-bolder fs-6' style={{color: '#e94600'}}>
                          ₹ {datas.projectAmount}
                        </span>
                      </div>
                      <label className='col-lg-3 fw-bold text-dark fs-6'>Additional Amount :</label>
                      <div className='col-lg-3'>
                        <span className='fw-bolder fs-6' style={{color: '#e95800'}}>
                          ₹ {datas.additionalAmount}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-5'>
                      <label className='col-lg-3 fw-bold text-dark fs-6'>Paid Amount :</label>
                      <div className='col-lg-3'>
                        <span className='fw-bolder fs-6 text-success'>₹ {datas.paidAmount}</span>
                      </div>
                      <label className='col-lg-3 fw-bold text-dark fs-6'>Remening Amount :</label>
                      <div className='col-lg-3'>
                        <span className='fw-bolder fs-6 text-danger' style={{color: '#e95800'}}>
                          ₹ {datas.remainingAmount}
                        </span>
                      </div>
                    </div>
                    <div className='row mb-5'>
                      <label className='col-lg-3 fw-bold text-dark fs-6'>Final Amount : </label>
                      <div className='col-lg-3'>
                        <span className='fw-bolder fs-6 text-info'>₹ {datas.finalAmount}</span>
                      </div>
                      {/* <label className='col-lg-3 fw-bold text-dark fs-6'>Stage Name :</label>
                  <div className='col-lg-3'>
                    <span className='fw-bolder badge badge-light-success  fw-bolder me-auto fs-5 text-primary'>
                      {datas.stageName}
                    </span>
                  </div> */}

                      <label
                        className={
                          datas.projectStageID === 1009
                            ? 'col-lg-3 fw-bold text-dark fs-6'
                            : 'd-none'
                        }
                      >
                        Handover Date :
                      </label>
                      <div className='col-lg-3'>
                        <span
                          className={
                            datas.projectStageID === 1009 ? 'fw-bolder fs-6 text-primary' : 'd-none'
                          }
                        >
                          {datas.statusDate}
                        </span>
                      </div>
                    </div>
                    <div className='d-flex justify-content-evenly'>
                      {/* -------------------------- Download Project ---------------------------- */}
                      <span
                        className='btn btn-md btn-light-primary bg-success text-white fs-5 mb-2 btn btn-rounded'
                        title='Download Project'
                        onClick={() => downloadPojectFile(datas.projectFilePath)}
                      >
                        Download Project Info
                      </span>
                      {/* -------------------------- Download Quotation ---------------------------- */}
                      <span
                        className='btn btn-md btn-light-primary bg-info text-white fs-5 mb-2 btn btn-rounded'
                        data-bs-toggle='tooltip'
                        title='Download Quotation'
                        onClick={() => downloadQuotationFile(datas.quetFilePath)}
                      >
                        {/* <KTSVG
                      path='/media/icons/duotune/files/fil017.svg'
                      className='svg-icon-2x svg-icon-primary'
                    /> */}
                        <span>Download Quotation</span>
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          {/* =================== Loading get Api Data ============== */}
          <BlankDataImageInTable length={data!.length} loading={false} colSpan={9} />
        </>
      )}
    </div>
  )
}

export {MainPage}
