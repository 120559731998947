import React from 'react'
import {useTabContext} from '../context/TabContext'
import {Link, useLocation} from 'react-router-dom'
import {IProjectModel} from '../../../models/dashboard-page/IDashboardModel'

interface IProps {
  objData: IProjectModel
}

const Header: React.FC<IProps> = ({objData}) => {
  const {activeTab, setActiveTab, data} = useTabContext()
  const location = useLocation()

  React.useEffect(() => {
    const path = location.pathname.substring(1)
    setActiveTab(path)
  }, [location, setActiveTab])

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <div className='card-body pt-2 pb-1 border border-bottom'>
      <div className='d-flex overflow-auto h-55px'>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
          <li className='nav-item'>
            <Link
              to='/home'
              onClick={() => handleTabClick('home')}
              className={
                `nav-link text-active-primary me-6 cursor-pointer active ` +
                (activeTab == 'home' && 'active')
              }
              // onClick={() => setAction('home', projectData.projectID)}
            >
              Project Info
            </Link>
          </li>
          <li className={data.length == 0 ? 'd-none' : 'nav-item'}>
            <Link
              to={{
                pathname: `/dashboard/wrapper/project/3DPhotos/${btoa(`${objData.projectID}`)}`,
                state: {objData},
              }}
              onClick={() => handleTabClick('Photos')}
              className={
                `nav-link text-active-primary me-6 cursor-pointer ` +
                (activeTab == `Photos` && 'active')
              }
              // onClick={() => setAction(`Photos`, projectData.projectID)}
            >
              3D Photos
            </Link>
          </li>
          <li className={data.length == 0 ? 'd-none' : 'nav-item'}>
            <Link
              to={{
                pathname: `/dashboard/wrapper/project/additional-item/${btoa(
                  `${objData.projectID}`
                )}`,
                state: {objData},
              }}
              onClick={() => handleTabClick('Additional Items')}
              className={
                `nav-link text-active-primary me-6 cursor-pointer ` +
                (activeTab == `Additional Items` && 'active')
              }
              // onClick={() => setAction(`Additional Items`, projectData.projectID)}
            >
              Additional Items
            </Link>
          </li>
          <li className={data.length == 0 ? 'd-none' : 'nav-item'}>
            <Link
              to={{
                pathname: `/dashboard/wrapper/project/reduction-item/${btoa(
                  `${objData.projectID}`
                )}`,
                state: {objData},
              }}
              onClick={() => handleTabClick('Reduction Items')}
              className={
                `nav-link text-active-primary me-6 cursor-pointer ` +
                (activeTab == `Reduction Items` && 'active')
              }
              // onClick={() => setAction(`Reduction Items`, projectData.projectID)}
            >
              Reduction Items
            </Link>
          </li>
          <li className={objData.projectStageID == 1009 ? 'nav-item' : 'd-none'}>
            <Link
              to={{
                pathname: `/dashboard/wrapper/project/my-complains/${btoa(`${objData.projectID}`)}`,
                state: {objData},
              }}
              onClick={() => handleTabClick('My Complains')}
              className={
                `nav-link text-active-primary me-6 cursor-pointer ` +
                (activeTab == `My Complains` && 'active')
              }
              // onClick={() => setAction(`My Complains`, projectData.projectID)}
            >
              My Complains
            </Link>
          </li>
          {/* <li className={activeTab === 'home' ? 'active' : ''}>
            <Link to="/home" onClick={() => handleTabClick('home')}>Home</Link>
          </li>
          <li className={activeTab === 'about' ? 'active' : ''}>
            <Link to="/about" onClick={() => handleTabClick('about')}>About</Link>
          </li>
          <li className={activeTab === 'contact' ? 'active' : ''}>
            <Link to="/contact" onClick={() => handleTabClick('contact')}>Contact</Link>
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default Header
