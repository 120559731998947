import React from 'react'
import ProjectHeaderInfo from './ProjectHeaderInfo'
import BackButton from './BackButton'
import {IProjectModel} from '../../../../models/dashboard-page/IDashboardModel'
import AddComplain from './AddComplain'

interface Props {
  objData: IProjectModel
}

const InfoHeader: React.FC<Props> = ({objData}) => {
  return (
    <>
      <div className='card-header d-flex flex-wrap flex-sm-nowrap align-items-center'>
        <ProjectHeaderInfo objData={objData} />
        <AddComplain objData={objData} />
        <BackButton />
      </div>
    </>
  )
}

export default InfoHeader
