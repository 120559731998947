import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_Montdor/layout/core'
import App from '../Dynamic using context/App'
import { Photos3DPage } from '../Dynamic using context/pages/Photos3DPage'
import { ComplainListPage } from '../Dynamic using context/pages/ComplainListPage'
import { AdditionalItemListPage } from '../Dynamic using context/pages/AdditionalItemListPage'
import AddComplainPage from '../Dynamic using context/pages/AddComplainPage'
import { ReductionItemListPage } from '../Dynamic using context/pages/ReductionItemListPage'

const CustomerProjectMasterPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/dashboard/wrapper/project/list'>
          <PageTitle>Customer Project List</PageTitle>
          {/* <CustomerProjectList /> */}
          {/* <CustomerProjectHeader /> */}
          <App />
        </Route>
        <Route path='/dashboard/wrapper/project/3DPhotos/:projectId'>
          <PageTitle>3D Photos</PageTitle>
          <Photos3DPage />
        </Route>
        <Route path='/dashboard/wrapper/project/additional-item/:projectId'>
          <PageTitle>Additional Items</PageTitle>
          <AdditionalItemListPage />
        </Route>
        <Route path='/dashboard/wrapper/project/reduction-item/:projectId'>
          <PageTitle>Reduction Items</PageTitle>
          <ReductionItemListPage />
        </Route>
        <Route path='/dashboard/wrapper/project/my-complains/:projectId'>
          <PageTitle>My Complains</PageTitle>
          <ComplainListPage />
        </Route>
        <Route path='/dashboard/wrapper/project/my-add-complains/:projectId'>
          <PageTitle>Add Complain</PageTitle>
          <AddComplainPage />
        </Route>
        {/* <Route path='/dashboard/wrapper/project/add'>
          <PageTitle>Add Complain</PageTitle>
          <AddComplainPage />
        </Route> */}
        {/* <Route path='/dashboard/wrapper/project/list/additional'>
          <PageTitle>Additional</PageTitle>
          <AdditionalItemMasterPage />
        </Route> */}
        <Redirect
          from='/dashboard/wrapper/project'
          exact={true}
          to='/dashboard/wrapper/project/list'
        />
        <Redirect to='/dashboard/wrapper/project/list' />
      </Switch>
    </>
  )
}
export default CustomerProjectMasterPage
