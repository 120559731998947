import React, {createContext, useContext, useState, useEffect, ReactNode} from 'react'
import {IProjectModel} from '../../../models/dashboard-page/IDashboardModel'
import {getProjectListByCustomerIDApi} from '../../../modules/dashboard-page/DashboardCRUD'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {toast} from 'react-toastify'

// Define the shape of the context data
interface TabContextType {
  activeTab: string
  loading: boolean
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
  data: IProjectModel[] // Replace with your data type
  setData: React.Dispatch<React.SetStateAction<IProjectModel[]>> // Replace with your data type
}

// Create the context with a default value (could be null)
const TabContext = createContext<TabContextType | undefined>(undefined)

// Create a provider component
export const TabProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [activeTab, setActiveTab] = useState<string>('home') // Default tab
  const [loading, setLoading] = useState<boolean>(false) // Default loader
  const [data, setData] = useState<IProjectModel[]>([] as IProjectModel[]) // Default data state

  useEffect(() => {
    setLoading(true)
    // Fetch data from API
    // fetch('https://api.example.com/data')
    //   .then(response => response.json())
    //   .then(data => setData(data))
    //   .catch(error => console.error('Error fetching data:', error));
    fetchAdminCountList()
  }, [])

  function fetchAdminCountList() {
    getProjectListByCustomerIDApi(user.customerID)
      .then((response) => {
        let responseData = response.data.responseObject
        if (response.data.isSuccess == true) {
          setData(responseData)
          setLoading(false)
        } else {
          setData(responseData)
          setLoading(false)
        }
      })
      .catch((error) => {
        toast.error(`${error}`)
        setData([])
        setLoading(false)
      })
  }

  return (
    <TabContext.Provider value={{activeTab, setActiveTab, data, setData, loading}}>
      {children}
    </TabContext.Provider>
  )
}

// Create a custom hook for easy access to the context
export const useTabContext = () => {
  const context = useContext(TabContext)
  if (context === undefined) {
    throw new Error('useTabContext must be used within a TabProvider')
  }
  return context
}
