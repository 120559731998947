import React from 'react'
import {IProjectModel} from '../../../../models/dashboard-page/IDashboardModel'

interface Props {
  objData: IProjectModel
}

const ProjectHeaderInfo: React.FC<Props> = ({objData}) => {
  return (
    <>
      <div className='d-flex flex-wrap flex-sm-nowrap'>
        {/* <div className='flex-grow-1'> */}
        {/* <div className='d-flex justify-content-between align-items-start flex-wrap'> */}
        {/* <div className='d-flex flex-column'> */}
        <div className='d-flex justify-content-center align-items-center'>
          <span className='text-primary text-dark cursor-pointer fs-4 fw-bolder'>
            Project Name : &nbsp;
            <span className='text-primary text-hover-danger cursor-pointer fs-4 fw-bolder'>
              {objData.projectName}
            </span>
          </span>
          {/* <span className='text-primary text-dark cursor-pointer fs-4 fw-bolder px-8'>
                  Customer Name : &nbsp;
                  <span className='text-primary text-hover-danger cursor-pointer fs-4 fw-bolder'>
                     {customerName} 
                  </span>
                </span> */}
        </div>
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </>
  )
}

export default ProjectHeaderInfo
