import React, {useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {IReductionlItemServiceModel} from '../../dashboard/IAdditionalItemServiceModel'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {getAllReductionItemListAPI} from '../../dashboard/AdditionalItemService'
import {IProjectModel} from '../../../models/dashboard-page/IDashboardModel'
import {toast} from 'react-toastify'
import LoaderInTable from '../../common-pages/LoaderInTable'
import BlankDataImageInTable from '../../common-pages/BlankDataImageInTable'
import {Pagination} from 'antd'
import InfoHeader from './Header/InfoHeader'

type Props = {}

interface IState {
  reductionlItemData: IReductionlItemServiceModel[]
  tmpReductionlItemData: IReductionlItemServiceModel[]
  objProjectList: IProjectModel
}

const ReductionItemListPage: React.FC<Props> = () => {
  const {projectId} = useParams<{projectId: string}>()
  const location = useLocation()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [mainLoading, setMainLoading] = useState<boolean>(false)

  let [state, setState] = useState<IState>({
    reductionlItemData: [] as IReductionlItemServiceModel[],
    tmpReductionlItemData: [] as IReductionlItemServiceModel[],
    objProjectList: {} as IProjectModel,
  })

  useEffect(() => {
    setMainLoading(true)
    setTimeout(() => {
      let lc: any = location.state
      let data: any = lc.objData
      getAllProject3dImageData(data)
    }, 100)
  }, [])

  function getAllProject3dImageData(data: IProjectModel) {
    getAllReductionItemListAPI(parseInt(atob(projectId)))
      .then((response) => {
        let responseData = response.data.responseObject
        if (response.data.isSuccess === true) {
          setState({
            ...state,
            reductionlItemData: responseData,
            tmpReductionlItemData: responseData,
            objProjectList: data,
          })
          setTotal(responseData.length)
          setMainLoading(false)
        } else {
          toast.error(`${response.data.message}`)
          setState({
            ...state,
            reductionlItemData: [],
            tmpReductionlItemData: [],
            objProjectList: data,
          })
          setMainLoading(false)
        }
      })
      .catch((error) => {
        toast.error(`${error}`)
        setState({
          ...state,
          reductionlItemData: [],
          tmpReductionlItemData: [],
        })
        setMainLoading(false)
      })
  }

  // =================-------------------------- Pagination --------------------------===================
  // const [posts, setPosts] = useState([])  // data
  const [total, setTotal] = useState(0) //  length
  const [page, setPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(10)
  const indexOfLastPage = page * postPerPage
  const indexOfFirstPage = indexOfLastPage - postPerPage
  const currentPosts: IReductionlItemServiceModel[] = state.reductionlItemData.slice(
    indexOfFirstPage,
    indexOfLastPage
  )
  const onShowSizeChange = (current: any, pageSize: any) => {
    setPostPerPage(pageSize)
  }

  return (
    <>
      {/* begin::Body */}
      <div className='card border border-2 border-primary border my-9 p-3'>
        <InfoHeader objData={state.objProjectList} />
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-striped align-middle g-6'>
            {/* begin::Table head */}
            <thead className='bg-light-primary'>
              <tr className='fw-bolder fs-5'>
                <th className='min-w-50px'>Date</th>
                <th className='min-w-250px'>Reductionl Item Name</th>
                <th className='min-w-505px text-end'>Amount</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {mainLoading ? (
                <LoaderInTable loading={mainLoading} column={15} />
              ) : (
                <>
                  {currentPosts.length > 0 &&
                    currentPosts.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td className=''>
                            <span className='text-dark text-hover-primary d-block mb-1 fs-6'>
                              {data.createDate}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark text-hover-primary d-block ps-2 mb-1 fs-6'>
                              {data.reductionItemDescription}
                            </span>
                          </td>
                          <td>
                            <span className='text-end text-dark text-hover-primary d-block ps-2 mb-1 fs-6'>
                              ₹ {data.reductionAmount}
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  <BlankDataImageInTable
                    length={currentPosts.length}
                    loading={mainLoading}
                    colSpan={5}
                  />
                </>
              )}
            </tbody>
          </table>
          <div className='text-center'>
            <Pagination
              onChange={(value: any) => setPage(value)}
              pageSize={postPerPage}
              total={total}
              current={page}
              showSizeChanger
              showQuickJumper
              onShowSizeChange={onShowSizeChange}
              showTotal={(total) => `Total ${total} items`}
            ></Pagination>
          </div>
        </div>
      </div>
    </>
  )
}

export {ReductionItemListPage}
