import React, {FC} from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import CustomerProjectMasterPage from './CustomerProjectMasterPage'
const DashboardWrapper: FC = () => {
  return (
    <>
      <Switch>
        <Route path='/dashboard/wrapper'>
          <CustomerProjectMasterPage />
        </Route>
        <Redirect
          from='/dashboard'
          exact={true}
          to='/dashboard/wrapper'
        />
        <Redirect to='/dashboard/wrapper' />
      </Switch>
    </>
  )
  
}

export {DashboardWrapper}
