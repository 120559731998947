import React from 'react';
import TabContent from './components/TabContent';
import { TabProvider } from './context/TabContext';

const App: React.FC = () => {
  return (
    <TabProvider>
      <TabContent />
    </TabProvider>
  );
};

export default App;
