import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap-v5'
import {toast} from 'react-toastify'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {
  createGetCustomerComplainRequestDetails,
  getAgencyTypeListAPI,
  getGetCustomerComplainWebListAPI,
} from '../../dashboard/MyComplainCRUD'
import {IAgencyTypeModel, IDescModel} from '../../../models/dashboard-page/IComplainModel'
import Loader from '../../common-pages/Loader'
import {IProjectModel} from '../../../models/dashboard-page/IDashboardModel'
import InfoHeader from './Header/InfoHeader'

interface IDepartment {
  loading: boolean
  agencyTypeData: IAgencyTypeModel[]
  complainCheckData: IDescModel[]
  objData: IProjectModel
  selAgencyTypeID: number
  projectID: number
  temComplnID: string
}

const AddComplainPage: React.FC = () => {
  const location = useLocation()
  const [fileLoader, setFileLoader] = useState(false)
  const history = useHistory()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [state, setState] = useState<IDepartment>({
    loading: false,
    agencyTypeData: [] as IAgencyTypeModel[],
    complainCheckData: [] as IDescModel[],
    objData: {} as IProjectModel,
    selAgencyTypeID: 0,
    projectID: 0,
    temComplnID: '',
  })

  useEffect(() => {
    setState({...state, loading: true})
    setTimeout(() => {
      const lc: any = location.state
      let objData: any = lc.objData
      getAllagencyTypeData(objData)
    }, 100)
  }, [])

  function getAllagencyTypeData(objData: IProjectModel) {
    getAgencyTypeListAPI()
      .then((response) => {
        if (response.data.isSuccess == true) {
          const responseData = response.data.responseObject
          setState({
            ...state,
            agencyTypeData: responseData,
            projectID: objData.projectID,
            objData,
            loading: false,
          })
        } else {
          toast.error(`${response.data.message}`)
          setState({...state, agencyTypeData: [], loading: false, objData})
        }
      })
      .catch((error) => {
        toast.error(`${error}`)
        setState({...state, agencyTypeData: [], loading: false})
      })
  }

  function handleShowComplainDesc(agencyTypeID: number) {
    getGetCustomerComplainWebListAPI(agencyTypeID)
      .then((response) => {
        const resComplainMapData = response.data.responseObject
        if (response.data.isSuccess === true) {
          let tmplstCheckedOutputData = []
          let resultOptputObj = resComplainMapData
          for (let k in resultOptputObj) {
            let tmpCheckedData = {
              complainID: resultOptputObj[k]['complainID'],
              complainDescription: resultOptputObj[k]['complainDescription'],
              isMember: 0,
              photoPath: '',
            }
            tmplstCheckedOutputData.push(tmpCheckedData)
          }
          setState({
            ...state,
            complainCheckData: tmplstCheckedOutputData,
            selAgencyTypeID: agencyTypeID,
            loading: false,
          })
        } else {
          setState({
            ...state,
            complainCheckData: resComplainMapData,
            loading: false,
          })
          toast.error(`${response.data.message}`, {position: 'top-center'})
        }
      })
      .catch((error) => {
        toast.error(`${error}`, {position: 'top-center'})
        setState({...state, complainCheckData: [], loading: false})
      })
  }

  // =================== For Area ==========================
  function AreaProjectItem(tech: IDescModel[]) {
    let tmpTech = tech
    let strSelTechid: string = ''
    let strSelPhotos: string = ''
    for (let k in tmpTech) {
      if (tmpTech[k].isMember === 1) {
        if (strSelTechid == '') {
          strSelTechid = `${tmpTech[k].complainID}`
          strSelPhotos = `${tmpTech[k].photoPath}`
        } else {
          strSelTechid = strSelTechid + ',' + `${tmpTech[k].complainID}`
          strSelPhotos = strSelPhotos + ',' + `${tmpTech[k].photoPath}`
        }
      }
    }
    addAreaByTurnkeyProductMstID(strSelTechid, strSelPhotos)
  }

  const [description, setDescription] = useState<string>('')
  function handleDescription(event: any) {
    const tmpDes = event.target.value
    setDescription(tmpDes)
  }

  // ================= Add Area Function =============
  function addAreaByTurnkeyProductMstID(strSelTechid: string, strSelPhotos: string) {
    if (state.selAgencyTypeID === 0) {
      return toast.error('Please Select Agency Type')
    }
    if (strSelTechid === '') {
      return toast.error('Please Select Complain')
    } else {
      createGetCustomerComplainRequestDetails(
        user.customerID,
        state.objData.projectID,
        state.selAgencyTypeID,
        description,
        strSelTechid,
        strSelPhotos,
        user.customerID,
        '192.66.22'
      )
        .then((response) => {
          if (response.data.isSuccess === true) {
            toast.success('Complain Created Successfully.', {position: 'top-center'})
            history.push({
              pathname: `/dashboard/wrapper/project/my-complains/${btoa(
                `${state.objData.projectID}`
              )}`,
              state: {objData: state.objData},
            })
          } else {
            toast.error(`${response.data.message}`, {position: 'top-center'})
            setState({...state, loading: false})
          }
        })
        .catch((error) => {
          toast.error(`${error}`, {position: 'top-center'})
          setState({...state, loading: false})
        })
    }
  }

  // ------------------------------
  function SetStatus(e: any) {
    let uid: number = e.target.id
    let isChecked = e.target.checked
    let name = e.target.name
    let tmpTechno = state.complainCheckData
    for (let k in tmpTechno) {
      if (uid == tmpTechno[k].complainID) {
        if (isChecked) {
          tmpTechno[k].isMember = 1
        } else {
          tmpTechno[k].isMember = 0
        }
        break
      }
    }
    setState({
      ...state,
      complainCheckData: tmpTechno,
    })
  }

  // --------------------------
  const selectChange = (event: any) => {
    const value = event.target.value
    const elementId = event.target.id
    if (elementId === 'agencyTypeID') {
      handleShowComplainDesc(parseInt(value))
    }
  }

  // -----------------upload photo----------------------
  const imageUpload = (e: any) => {
    const elementId = e.target.id
    setFileLoader(true)
    e.preventDefault()
    const formData = new FormData()
    formData.append('file', e.target.files[0], e.target.files[0].name)
    fetch(
      process.env.REACT_APP_API_URL +
        `/CustomerComplain/UploadComplainPhotoPath/${state.objData.projectID}`,
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        let tmpTechno = state.complainCheckData
        for (let k in tmpTechno) {
          if (elementId == tmpTechno[k].complainID) {
            tmpTechno[k].photoPath = data
            break
          }
        }
        setState({
          ...state,
          complainCheckData: tmpTechno,
        })
        setFileLoader(false)
      })
  }

  return (
    <>
      <div className='card border border-2 border-primary border my-9 p-3'>
        <InfoHeader objData={state.objData} />
        {state.loading ? (
          <Loader loading={state.loading} />
        ) : (
          <>
            {/* <div className='card m-15 me-10'> */}
            {/* <div id='kt_account_profile_details' className='collapse show'> */}
            {/* <form className='form'> */}
            <div className='card-body border-top mb-3 mb-xl-5 '>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                  Agency Type :
                </label>
                <div className='col-lg-4 fv-row'>
                  <select
                    className='form-select bg-light-primary'
                    aria-label='Default select example'
                    onChange={selectChange}
                    id='agencyTypeID'
                  >
                    <option selected={state.selAgencyTypeID === 0 ? true : false} value={0}>
                      Select Agency Type
                    </option>
                    {state.agencyTypeData.length > 0 &&
                      state.agencyTypeData.map((data, index) => {
                        return (
                          <option
                            key={index}
                            value={data.agencyTypeID}
                            selected={state.selAgencyTypeID == data.agencyTypeID ? true : false}
                          >
                            {data.agencyTypeName}
                          </option>
                        )
                      })}
                  </select>
                </div>
              </div>
              <div className={'row mb-6'}>
                <label className='col-lg-2 col-form-label fw-bold fs-6 mt-10'>
                  <div className='required'>Select Complain :</div>
                </label>
                <div
                  className={
                    state.complainCheckData.length == 0 ? 'd-none' : `col-lg-10 box-shadow-0 mt-5`
                  }
                >
                  {state.complainCheckData.length > 0 &&
                    state.complainCheckData.map((item, index) => (
                      <div key={index}>
                        <div className='form-check form-check-custom form-check-solid mb-4'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`${item.complainID}`}
                            value={item.complainID}
                            name={item.complainDescription}
                            checked={item.isMember == 1 ? true : false}
                            onChange={(e) => SetStatus(e)}
                          />
                          <label
                            className='form-check-label text-start ms-5 col-lg-2'
                            htmlFor='flexCheckDefault'
                          >
                            {item.complainDescription}
                          </label>
                          <label className='col-lg-2 ms-5 col-form-label fw-bold fs-6'>
                            <span className='d-block'>Upload Photo:</span>
                            <p className='text-muted fs-7'> (allow only .jpg files)</p>
                          </label>
                          <div
                            className={
                              item.photoPath === ''
                                ? 'd-none'
                                : 'ms-5 col-lg-1 d-flex align-items-center'
                            }
                          >
                            <div className='symbol symbol-45px me-5 ms-2'>
                              <img src={process.env.REACT_APP_API_URL + item.photoPath} alt='img' />
                            </div>
                          </div>
                          <div
                            className={
                              item.photoPath === ''
                                ? 'col-lg-7 fv-row'
                                : 'ms-2 text-end col-lg-6 fv-row'
                            }
                          >
                            <input
                              type='file'
                              accept='image/*'
                              className='form-control form-control-lg form-control-solid bg-light-primary'
                              onChange={(e) => imageUpload(e)}
                              id={`${item.complainID}`}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-2 col-form-label fw-bold fs-6'>
                  <span className=''>Remarks :</span>
                </label>
                <div className='col-lg-10 fv-row'>
                  <textarea
                    rows={4}
                    className='form-control form-control-lg form-control-solid bg-light-primary'
                    placeholder='Remarks...'
                    value={description}
                    onChange={handleDescription}
                  />
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9 ms-2'>
                <Button variant='primary' onClick={() => AreaProjectItem(state.complainCheckData)}>
                  Submit
                </Button>
                <button
                  onClick={() =>
                    history.push({
                      pathname: `/dashboard/wrapper/project/my-complains/${btoa(
                        `${state.objData.projectID}`
                      )}`,
                      state: {objData: state.objData},
                    })
                  }
                  className='btn btn-danger ms-3'
                >
                  Cancel
                </button>
              </div>
            </div>
            {/* </form> */}
            {/* </div> */}
            {/* </div> */}
          </>
        )}
      </div>
    </>
  )
}
export default AddComplainPage
