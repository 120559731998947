import React from 'react'
import {Link} from 'react-router-dom'

const BackButton = () => {
  return (
    <>
      <div className='text-end mb-2 me-2'>
        <span>
          <Link
            className='btn btn-sm btn-light-danger bg-danger text-white fs-5 btn btn-rounded'
            to={{pathname: '/dashboard/wrapper/project/list'}}
          >
            Back To List
          </Link>
        </span>
      </div>
    </>
  )
}

export default BackButton
