import React, {useEffect, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {getProjectProject3dImageList} from '../../dashboard/AdditionalItemService'
import {IProjectModel} from '../../../models/dashboard-page/IDashboardModel'
import {toast} from 'react-toastify'
import LoaderInTable from '../../common-pages/LoaderInTable'
import {KTSVG, toAbsoluteUrl} from '../../../../_Montdor/helpers'
import BlankDataImageInTable from '../../common-pages/BlankDataImageInTable'
import {Pagination} from 'antd'
import {Button, Modal} from 'react-bootstrap-v5'
import {I3dImagesModel} from '../../dashboard/IAdditionalItemServiceModel'
import BackButton from './Header/BackButton'
import InfoHeader from './Header/InfoHeader'

type Props = {}

interface IState {
  imagesData: I3dImagesModel[]
  tmpimagesData: I3dImagesModel[]
  objProjectList: IProjectModel
  selAdditionalID: number
  imageShow: string
  pathUrl: any
}

const Photos3DPage: React.FC<Props> = () => {
  const location = useLocation()
  const [mainLoading, setMainLoading] = useState<boolean>(false)
  const {projectId} = useParams<{projectId: string}>()

  let [state, setState] = useState<IState>({
    imagesData: [] as I3dImagesModel[],
    tmpimagesData: [] as I3dImagesModel[],
    objProjectList: {} as IProjectModel,
    selAdditionalID: 0,
    imageShow: '',
    pathUrl: process.env.REACT_APP_API_URL,
  })

  useEffect(() => {
    setMainLoading(true)
    setTimeout(() => {
      let lc: any = location.state
      console.log(lc)
      let data:any = lc.objData
      getAllProject3dImageData(data)
    }, 100)
  }, [])

  function getAllProject3dImageData(data: IProjectModel) {
    getProjectProject3dImageList(parseInt(atob(projectId)))
      .then((response) => {
        let responseData = response.data.responseObject
        if (response.data.isSuccess === true) {
          setState({
            ...state,
            imagesData: responseData,
            tmpimagesData: responseData,
            objProjectList: data,
          })
          setTotal(responseData.length)
          setMainLoading(false)
        } else {
          toast.error(`${response.data.message}`)
          setState({
            ...state,
            imagesData: [],
            tmpimagesData: [],
            objProjectList: data,
          })
          setMainLoading(false)
        }
      })
      .catch((error) => {
        toast.error(`${error}`)
        setState({
          ...state,
          imagesData: [],
          tmpimagesData: [],
        })
        setMainLoading(false)
      })
  }

  // ====================Images Flag============
  const [showFlag, setShowFlag] = useState(false)
  const handleCloseFlag = () => {
    setState({...state, imageShow: ''})
    setMainLoading(false)
    setShowFlag(false)
  }
  const handleShowFlag = (selImg: string) => {
    setState({...state, imageShow: process.env.REACT_APP_API_URL + selImg})
    setMainLoading(false)
    setShowFlag(true)
  }

  async function downloadFile(selURL: string) {
    var fullUrl = state.pathUrl + selURL
    const link = document.createElement('a')
    link.target = '_blank'
    link.href = `${fullUrl}`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // =================-------------------------- Pagination --------------------------===================
  // const [posts, setPosts] = useState([])  // data
  const [total, setTotal] = useState(0) //  length
  const [page, setPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(10)
  const indexOfLastPage = page * postPerPage
  const indexOfFirstPage = indexOfLastPage - postPerPage
  const currentPosts: I3dImagesModel[] = state.imagesData.slice(indexOfFirstPage, indexOfLastPage)
  const onShowSizeChange = (current: any, pageSize: any) => {
    setPostPerPage(pageSize)
  }

  return (
    <>
      {/* begin::Body */}
      <div className='card border border-2 border-primary border my-9 p-3'>
        <InfoHeader objData={state.objProjectList} />
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-striped align-middle g-6'>
            {/* begin::Table head */}
            <thead className='bg-light-primary'>
              <tr className='fw-bolder fs-5'>
                <th className='min-w-150px'>
                  <span className='d-block text-start'>Title</span>
                </th>
                <th className='min-w-150px'>
                  <span className='d-flex'>Download</span>
                </th>
                <th className='min-w-200px text-end'>View</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {mainLoading ? (
                <LoaderInTable loading={mainLoading} column={15} />
              ) : (
                <>
                  {currentPosts.length > 0 &&
                    currentPosts.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div
                                className='symbol symbol-45px me-5'
                                onClick={() => handleShowFlag(data.photoPath)}
                              >
                                {data.photoPath !== '' ? (
                                  <img
                                    src={process.env.REACT_APP_API_URL + data.photoPath}
                                    alt=''
                                  />
                                ) : (
                                  <img
                                    src={toAbsoluteUrl('/media/img/NoProductImage.png')}
                                    alt=''
                                  />
                                )}
                              </div>
                              <div className='d-flex justify-content-center flex-column'>
                                <span className='text-dark text-hover-primary d-block mb-1 fs-6'>
                                  {data.photoTitle}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className=''>
                            <span
                              className='btn btn-icon btn-bg-light bg-hover-primary text-hover-inverse-primary btn-sm'
                              onClick={() => downloadFile(data.photoPath)}
                            >
                              <KTSVG
                                path='/media/icons/duotune/files/fil017.svg'
                                className='svg-icon-fluid svg-icon-primary'
                              />
                            </span>
                          </td>
                          <td className='text-end'>
                            <span
                              className='btn btn-icon btn-bg-light bg-hover-primary text-hover-inverse-primary btn-sm me-1 text-primary text-hover-light'
                              onClick={() => handleShowFlag(data.photoPath)}
                            >
                              <span className='fa fa-eye fs-2'></span>
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                  <BlankDataImageInTable
                    length={currentPosts.length}
                    loading={mainLoading}
                    colSpan={5}
                  />
                </>
              )}
            </tbody>
          </table>
          <div className='text-center'>
            <Pagination
              onChange={(value: any) => setPage(value)}
              pageSize={postPerPage}
              total={total}
              current={page}
              showSizeChanger
              showQuickJumper
              onShowSizeChange={onShowSizeChange}
              showTotal={(total) => `Total ${total} items`}
            ></Pagination>
          </div>
        </div>
      </div>

      {/* =====================Image Model=================== */}
      <Modal
        size='lg'
        show={showFlag}
        onHide={handleCloseFlag}
        backdrop='true'
        keyboard={false}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className='text-muted text-light'>3D Image </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-5'>
            <img
              alt='image not found'
              className='img-fluid'
              src={
                state.imageShow == ''
                  ? toAbsoluteUrl('/media/img/NoProductImage.png')
                  : toAbsoluteUrl(`${state.imageShow}`)
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={handleCloseFlag}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {Photos3DPage}
