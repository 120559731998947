import axios from 'axios'
const BASE_API_URL = process.env.REACT_APP_API_URL

// =================dashboard URL=====================
export const Get_Customer_ComplainListBy_CustomerID_List = `${BASE_API_URL}/CustomerComplain/GetCustomerComplainListByCustomerID` //Get Admin Count
export const Get_AgencyType_List = `${BASE_API_URL}/AgencyType/GetAgencyTypeList` //Get Admin Count
export const Get_Customer_Complain_Web_List = `${BASE_API_URL}/CustomerComplain/GetComplainDescriptionList` //Get Admin Count
export const Add_Customer_Complain_Request_Details = `${BASE_API_URL}/CustomerComplain/CustomerComplainRequestDetails` //Get Admin Count
export const Complain_Approve_By_Customer = `${BASE_API_URL}/CustomerComplain/ComplainApproveByCustomer` //Get Admin Count

// -----------------Complain List Api -------------
export function getGetCustomerComplainListApi(customerID: number, projectID: number) {
  return axios.post(Get_Customer_ComplainListBy_CustomerID_List, {customerID, projectID})
}
// =========================Get Agency=====================
export function getAgencyTypeListAPI() {
  return axios.get(Get_AgencyType_List)
}
// -------------------------Complain Check Api-----------
export function getGetCustomerComplainWebListAPI(agencyTypeID: number) {
  return axios.post(Get_Customer_Complain_Web_List, {agencyTypeID})
}
export function getCustomerComplainApproveByAPI(customerComplainMainID: number) {
  return axios.post(Complain_Approve_By_Customer, {customerComplainMainID})
}

export function createGetCustomerComplainRequestDetails(
  customerID: number,
  projectID: number,
  agencyTypeID: number,
  complainDescription: string,
  complainIDs: string,
  photoPath: string,
  createBy: number,
  ipAddress: string
) {
  return axios.post(Add_Customer_Complain_Request_Details, {
    customerID,
    projectID,
    agencyTypeID,
    complainDescription,
    complainIDs,
    photoPath,
    createBy,
    ipAddress,
  })
}
