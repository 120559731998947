import React, {useEffect, useState} from 'react'
import {IProjectModel} from '../../../../models/dashboard-page/IDashboardModel'
import {Link, useLocation} from 'react-router-dom'
import {KTSVG} from '../../../../../_Montdor/helpers'
import moment from 'moment'

interface Props {
  objData: IProjectModel
}
const AddComplain: React.FC<Props> = ({objData}) => {
  const location = useLocation()
  const [expComplainDate, setExpComplainDate] = useState(moment().format('YYYY-MM-DD'))
  const [curntComplainDate, setCurntComplainDate] = useState(moment().format('YYYY-MM-DD'))

  useEffect(() => {
    setTimeout(() => {
      let lc: any = location.state
      let data: any = lc.objData
      getCustomerComplainData(data)
    }, 100)
  }, [])

  function getCustomerComplainData(data: IProjectModel) {
    let d = new Date(data.statusDate)
    d.setDate(d.getDate() + data.maintenanceDays)
    let dateString = moment(d).format('YYYY-MM-DD')
    setExpComplainDate(dateString)
  }

  return (
    <>
      {objData.statusDate <= expComplainDate &&
      curntComplainDate <= expComplainDate &&
      location.pathname ==
        `/dashboard/wrapper/project/my-complains/${btoa(`${objData.projectID}`)}` ? (
        // <div className='card-header border-0' style={{backgroundColor: '#404040'}}>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add Complain Request'
        >
          <Link
            className='btn btn-sm btn-light-primary bg-primary text-light fs-5 text-hover-light bg-hover-primary text-hover-inverse-success btn btn-rounded d-flex align-item-center'
            to={{
              pathname: `/dashboard/wrapper/project/my-add-complains/${btoa(
                `${objData.projectID}`
              )}`,
              state: {objData: objData},
            }}
          >
            {/* <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon svg-icon-3 svg-icon-light' /> */}
            <span className='fa fa-plus mt-1 me-3'></span>
            Add New
          </Link>
        </div>
      ) : (
        // </div>
        <></>
      )}
    </>
  )
}

export default AddComplain
